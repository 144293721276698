@tailwind base;
@tailwind components;

@font-face {
  font-family: "poppinsGoogleRegular";
  font-weight: 400;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "poppinsGoogleMedium";
  font-weight: 500;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Medium.ttf) format("ttf");
}

@font-face {
  font-family: "poppinsGoogleSemiBold";
  font-weight: 600;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "poppinsGoogleBold";
  font-weight: 700;
  src: local("poppins"), url(./Fonts/Poppins/Poppins-Bold.ttf) format("ttf");
}

@font-face {
  font-family: "poppinsGoogleExtraBold";
  font-weight: 800;
  src: local("poppins"),
    url(./Fonts/Poppins/Poppins-ExtraBold.ttf) format("ttf");
}

@tailwind utilities;

@layer base {
  html {
    font-family: poppinsGoogle, system-ui, sans-serif;
  }
}

/* custom style fixes */
.z-index-120 {
  z-index: 120;
}

.req-field:after {
  content: " *";
  color: #f66060;
}

.dashboard-box-shadow {
  box-shadow: 0px 10px 30px rgba(180, 180, 180, 0.25);
}

/* red: remove arrow buttons from all inputs that has type=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.absolute-start {
  @apply absolute top-1/2 left-0 transform -translate-y-1/2
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
.table-border-outer {
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #D1D0E2; /* this draws the table border  */ 
}

/* custom select element styling starts */
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
/* custom select element styling ends */